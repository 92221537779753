<template>
    <div class="container_con">
        <el-card v-if="form" >
            <div class="con_title"><span></span> 基础信息</div>
            <div class="flex" style="align-items: flex-start;">
                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">日期:</div>
                        <div class="flex1">{{form.record_date}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">适应温度范围:</div>
                        <div class="flex1">{{form.temp_lower+'℃至'+form.temp_upper}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">适应湿度范围:</div>
                        <div class="flex1">{{form.humidity_lower+'%至'+form.humidity_upper}}%</div>
                    </div>
                </div>
            </div>
            
            <div class="con_title" style="margin-top: 30px;"><span></span> 上午温湿度</div>
            <div class="flex" style="align-items: flex-start;">
                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">上午温度:</div>
                        <div class="flex1">{{form.temp_am}}℃</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">上午湿度:</div>
                        <div class="flex1">{{form.humidity_am}}%</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">上午超标采取措施:</div>
                        <div class="flex1">{{form.measure_am}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">上午采取措施后温度:</div>
                        <div class="flex1">{{form.measure_temp_am}}℃</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">上午采取措施后湿度:</div>
                        <div class="flex1">{{form.measure_humidity_am}}%</div>
                    </div>
                </div>
            </div>
                
                
            <div class="con_title" style="margin-top: 30px;"><span></span> 下午温湿度</div>
            <div class="flex" style="align-items: flex-start;">
                <div class="items flex flex_wrap">
                    <div class="flex_ac item">
                        <div class="tt">下午温度:</div>
                        <div class="flex1">{{form.temp_pm}}℃</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">下午湿度:</div>
                        <div class="flex1">{{form.humidity_pm}}%</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">下午超标采取措施:</div>
                        <div class="flex1">{{form.measure_pm}}</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">下午采取措施后温度:</div>
                        <div class="flex1">{{form.measure_temp_pm}}℃</div>
                    </div>
                    <div class="flex_ac item">
                        <div class="tt">下午采取措施后湿度:</div>
                        <div class="flex1">{{form.measure_humidity_pm}}%</div>
                    </div>
                </div>
            </div>
            
            <div class="bottom_btn" style="justify-content: flex-start;">
                <el-button @click="$router.go(-1)">返回</el-button>
            </div>

        </el-card>
        <!-- 上传图片预览 -->
        <el-dialog :close-on-click-modal="false" title="图片预览" width="600px" v-model="uploadImgVisible">
            <img style="width: 100%;" :src="uploadImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
    // 上传组件
    export default {
        name: "productDetail",
        data() {
            return {
                form: '',
                imgs2: [],
                imgs3: [],
                imgs4: [],
                imgs5: [],
                // 上传图片
                uploadImageUrl: '',
                uploadImgVisible: false,
            };
        },
        created() {
            this.id = this.$route.query.id;
            this.get_data()
        },
        methods: {
            PicturePreview(file) {
                this.uploadImageUrl = file.url;
                this.uploadImgVisible = true;
            },
            get_data() {
                this.$httpGet("/backend/WarehouseTempHumidity/read", {
                    id: this.id
                }).then((res) => {
                    if (res.status == 200) {
                        let _data = res.data;
                        this.form = _data;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .items {
        width: 100%;
        padding-right: 30px;
    }

    .items .item {
        padding: 10px;
        width: 100%;
        padding-right: 30px;
        line-height: 24px;
        color: #333;
        font-size: 14px;
    }

    .items .item .tt {
        color: #888;
        width: 150px
    }

    .avatar {
        height: 80px;
    }
</style>
